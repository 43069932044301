
body{
  background-color: rgb(23, 23, 67);
  height: 100%;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

p{
  text-indent: 50px;
  font-size: 26px;
}
.container{
  position: relative;
  top: 100px;
  height: fit-content;
  text-align: center;
  overflow-y: clip;
}

.projectPage{
  position: relative;
  top: 100px;
  text-align: center;
}

.image{
  height: 50%;
  width: 75%
}

.link{
  color: rgb(218, 71, 149);
  font-size: 32px;
}

.gitLink{
  color: rgb(218, 71, 149);
  font-size: 32px;
}

.navigationBar{
  color: green;
  width: 20%;
  text-align: center;
  background-color: rgb(23, 23, 67);
  border: 1px solid rgb(87, 164, 138);
  position: fixed;
  right: 0px;
  z-index: 3;
}


.horizontalHidden{
  background-color: aquamarine;
  position: absolute;
  width: 100%;
  height: 0px;
  top:0%;
  z-index: 1;
  transition: height 3s;
}

.verticalHidden{
  background-color: aquamarine;
  position: absolute;
  height: 100%;
  width: 0px;
  left: -5%;
  z-index: 1;
  transition: width 3s;
}

.horizontalVisible{
  background-color: aquamarine;
  position: absolute;
  top: 0%;
  left: -10px;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: height 3s;

}

.verticalVisible{
  background-color: rgb(87, 164, 138);
  opacity: 80%;
  position: absolute;
  left: 0%;
  top: 20%;
  height: 70%;
  width: 120%;
  z-index: 0;
  overflow: visible;
}

.crosshairH{
  background-color: rgb(36, 127, 166);
  width: 110%;
  height: 1px;
  opacity: .25;
  position: absolute;
  z-index: 0;
}

.crosshairV{
  background-color: rgb(36, 127, 166);
  width: 1px;
  height: 110%;
  opacity: .25;
  position: absolute;
  z-index: 0;
}

.project{
  height: 300px;
  width: 400px;
  border-radius: 8px;
  z-index: 2;
}

.projectsContainer{
  padding-top: 200px;
  display: flex;
  justify-content: space-evenly;
  gap: 100px;
  z-index: 2;
}

.projectBox{
  width: 400px;
  height: 450px;
  margin: 1%;
  color: rgb(36, 166, 86);
  text-align: center;
  overflow-x: hidden;
  background-color: rgb(45, 45, 118);
  border: 1px solid rgb(154, 252, 127);
  border-radius: 8px;
  z-index: 1;
}

.projectInfo{
  position: relative;
  text-align: left;
  left: 20%;
  color: rgb(36, 166, 86);
  overflow-y: visible;
  width: 60%;

}


.intro{
  color: rgb(255, 255, 255);
  font-family: "Open Sans", sans-serif;
  text-align: left;
  padding-left: 20%;
  font-size: 48px;
  position: relative;
  z-index: 2;
}

.intro2{
  color: rgb(36, 166, 86);

  font-size: 26px;
  position: relative;
  z-index: 2;
}

.intro3{
  color: rgb(36, 166, 86);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  z-index: 2;
}

.button{
  position: relative;

  z-index: 2;
  border:1px solid rgb(36, 166, 86);
  border-radius: 3px;
  color: rgb(36, 166, 86);
  background-color: rgb(23, 23, 67);
}

.hoverButton{
  position: relative;
  z-index: 2;
  border:1px solid rgb(36, 166, 86);
  color: rgb(255, 255, 255);
  background-color: rgb(36, 166, 85);
}

.bigPic{
  height: 50%;
  width: 50%;
}

.externalLink{
  font-size: 30px;
  color:rgb(218, 71, 149);
  align-self:center;
}


/*------------------




MOBILE STYLES



------------------*/


.containerMobile{
  position: relative;
  top: 100px;
  height: fit-content;
  text-align: center;
  overflow-y: clip;
}

.projectPageMobile{
  position: relative;
  top: 100px;
  text-align: center;
}

.imageMobile{
  height: 50%;
  width: 75%
}

.linkMobile{
  color: rgb(218, 71, 149);
  text-align: center;
  font-size: x-large;
}

.gitLinkMobile{
  color: rgb(218, 71, 149);
  font-size: x-large;
}

.navigationBarMobile{
  color: green;
  width: 50%;
  text-align: center;
  background-color: rgb(23, 23, 67);
  border: 1px solid rgb(87, 164, 138);
  position: fixed;
  right: 20%;
  z-index: 3;
  -webkit-backface-visibility: hidden;
}


.horizontalHiddenMobile{
  background-color: aquamarine;
  position: absolute;
  width: 100%;
  height: 0px;
  top:0%;
  z-index: 1;
  transition: height 3s;
}

.verticalHiddenMobile{
  background-color: aquamarine;
  position: absolute;
  height: 100%;
  width: 0px;
  left: -5%;
  z-index: 1;
  transition: width 3s;
}

.horizontalVisibleMobile{
  background-color: aquamarine;
  position: absolute;
  top: 0%;
  left: -10px;
  height: 100%;
  width: 100%;
  z-index: 0;
  transition: height 3s;

}

.verticalVisibleMobile{
  background-color: rgb(36, 166, 85);
  opacity: 80%;
  position: absolute;
  left: 0%;
  top: 20%;
  height: 150%;
  width: 120%;
  z-index: 0;
  overflow: visible;
}

.crosshairHMobile{
  background-color: rgb(36, 127, 166);
  width: 110%;
  height: 1px;
  opacity: .25;
  position: absolute;
  z-index: 0;
}

.crosshairVMobile{
  background-color: rgb(36, 127, 166);
  width: 1px;
  height: 110%;
  opacity: .25;
  position: absolute;
  z-index: 0;
}

.projectMobile{
  height: 100%;
  width: 100%;
  border-radius: 8px;
  z-index: 2;
}

.projectsContainerMobile{
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 5%;
  z-index: 2;
}

.projectBoxMobile{
  width: 60%;
  height: 25%;
  margin: 5%;
  font-size: 12px;
  color: rgb(36, 166, 85);
  text-align: center;
  overflow-x: hidden;
  background-color: rgb(41, 58, 128);
  border: 1px solid black;
  border-radius: 8px;
  z-index: 1;
}

.projectInfoMobile{
  position: relative;
  text-align: left;
  left: 10%;
  color: rgb(36, 166, 86);
  overflow-y: visible;
  width: 80%;

}


.introMobile{
  color: rgb(255, 255, 255);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  z-index: 2;
}

.introMobile2{
  color: rgb(36, 166, 86);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  z-index: 2;
}

.introMobile3{
  color: rgb(36, 166, 86);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  z-index: 2;
}

.buttonMobile{
  position: relative;
  z-index: 2;
  border:1px solid rgb(36, 166, 86);
  color: rgb(36, 166, 86);
  background-color: rgb(23, 23, 67);
}

.hoverButtonMobile{
  position: relative;
  z-index: 2;
  border:1px solid rgb(36, 166, 86);
  color: rgb(255, 255, 255);
  background-color: rgb(36, 166, 85);
}

.bigPicMobile{
  height: 50%;
  width: 50%;
}